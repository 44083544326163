import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import SearchInput from '../../../../Components/Form/SearchInput';
import FilterDropdown from '../../../../Components/Filter/FilterDropdown';
import { Icon, Icons } from '../../../../Components/Icon';
import EmptyRecord from '../../../../Components/EmptyRecord';
import Button from '../../../../Components/Form/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { getInvoices } from '../../../../service/invoice';
import { InvoiceDoc, InvoiceStatus, InvoiceType } from '../../../../types/invoice.type';
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions';
import Loader from '../../../../Assests/loader.gif';
import { formatCurrency, getDateStatus } from '../../../../utils';
import { format } from 'date-fns';
import Status from '../../../../Components/Status';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../../../Context/Context';
import { SlOptionsVertical } from 'react-icons/sl';
import Action from '../../../../Components/Modal/Invoice/Action';
import PaginationCount from '../../../../Components/Pagination/PaginationCount';
import Pagination from '../../../../Components/Pagination/Pagination';

export default function Invoice() {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [invoicesData, setInvoicesData] = useState<InvoiceType | null>(null);
  const [filterValue, setFilterValue] = useState('all');
  const [filteredData, setFilteredData] = useState<InvoiceDoc[] | null>(null);
  const [invoiceId, setInvoiceId] = useState<InvoiceDoc | null>();
  const [activeIndex, setActiveIndex] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [runAfterPayment, setRunAfterPayment] = useState(false);

  const navigate = useNavigate();
  const { setSelectedInvoiceId } = useGlobalContext();

  const { docs } = useAppSelector(selectBusiness);

  const fetchData = useCallback(
    async (page: number, filterBy?: string) => {
      const invoices = await getInvoices(docs[0].businessID._id, filterBy, page);
      setIsLoading(false);
      setInvoicesData(invoices);
    },
    [docs]
  );

  useEffect(() => {
    if (docs[0].businessID.status !== 'active') {
      toast.info('You need to verify your business first');
      return;
    }
    setIsLoading(true);
    fetchData(currentPage, filterValue.length < 1 ? 'all' : filterValue);
  }, [runAfterPayment]);

  const selectFilter = (filterBy: string) => {
    setFilterValue(filterBy);
    if (filterBy === 'drafts') {
      navigate('/invoices-draft');
    } else {
      setIsLoading(true);
      fetchData(currentPage, filterBy ? filterBy : 'all');
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    const filteredData = invoicesData?.docs.filter((item: any) => {
      const SearchMacth = item.number.toLowerCase().includes(searchTerm.toLowerCase());

      if (filterValue === 'buyer') {
        const bussinesName = item.businessID.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

        return bussinesName || SearchMacth;
      } else {
        const sellersName = item.sellerName
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        return sellersName || SearchMacth;
      }
    });
    setFilteredData(filteredData as InvoiceDoc[]);
  };

  //pagination
  // const paginationClick = (num: any) => {
  //   setIsLoading(true);
  //   if (filterValue) {
  //     if (filterValue === 'supplier') {
  //       fetchData(num);
  //     } else {
  //       fetchData(num);
  //     }
  //   } else {
  //     fetchData(num, 'supplier');
  //   }
  // };

  const shortenName = (name: string) => {
    if (!isLoading) {
      if ((name ?? '')?.length > 10) {
        return `${name.slice(0, 10)}...`;
      } else {
        return name;
      }
    }
  };

  const getStatusLabel = (status: string, filterValue: string) => {
    switch (status) {
      case 'awaiting_internal_approval':
        return 'Awaiting Internal Approval';
      case 'approved':
        return 'Approved Internally';
      case 'declined_internally':
        return 'Declined Internally';
      case 'awaiting_customer_approval':
        return filterValue === 'buyer' ||
          docs[0]?.businessID?._id === invoicesData?.docs[0]?.sellerID?._id
          ? 'Awaiting your approval'
          : 'Sent to Customer';
      case 'customer_declined':
        return 'Declined by Customer';
      case 'approved_for_financing':
        return 'Approved by Customer';
      case 'outstanding':
        return 'Outstanding';
      case 'draft':
        return 'Draft';
      case 'seller_internal_approved':
        return 'Seller Internal Approved';
      default:
        return 'Fully Paid';
    }
  };

  const [paginateNumber, setPaginateNumber] = useState<number | null>(null);

  useEffect(() => {
    setIsLoading(true);
    if (filterValue && paginateNumber) {
      fetchData(paginateNumber, filterValue);
    }
  }, [filterValue, paginateNumber]);

  const handleClick = (item: any) => {
    if (activeIndex) {
      setInvoiceId(null);
      setSelectedInvoiceId(null);
      setActiveIndex(null);
    } else {
      setInvoiceId(item);
      setSelectedInvoiceId(item._id);
      setActiveIndex(item?._id);
    }
  };

  return (
    <div>
      <Breadcrumb
        text="Invoices"
        subText={
          filterValue === 'supplier'
            ? 'Receivables'
            : filterValue === 'buyer'
            ? 'Payables'
            : 'All'
        }
      />
      <div className="mt-10">
        <div className="flex flex-wrap items-center justify-between gap-y-[20px]">
          <CheckPermissions requiredPermissions={['view_invoice']}>
            <div className="w-1/3">
              <SearchInput
                value={search}
                placeholder="Search"
                name={''}
                className="h-10"
                onChange={handleSearch}
                style="max-sm:flex sm:flex min-w-[200px]"
              />
            </div>
          </CheckPermissions>
          <div className="flex items-center gap-2  px-2 max-[530px]:px-0">
            <CheckPermissions requiredPermissions={['view_invoice']}>
              <FilterDropdown
                iconPosition="left"
                icon={<Icon name={Icons.Filter} />}
                setSelectedOrder={(val) => console.log('FilterDropdown', val)}
                label="Filter"
                handleTableSearch={(val) => selectFilter(val)}
                options={[
                  { value: 'supplier', label: 'Receivables' },
                  { value: 'buyer', label: 'Payables' },
                  { value: 'drafts', label: 'Drafts' },
                ]}
                disabled={docs[0].businessID.status === 'active' ? false : true}
                activeIndicator={filterValue ?? 'all'}
                all="all"
              />
            </CheckPermissions>
            <CheckPermissions requiredPermissions={['create_invoice', '*']}>
              <Link to="create">
                <Button
                  type="button"
                  variant="primary"
                  size="custom"
                  label="Create Invoice"
                  className="px-5 py-2.5 text-sm"
                  icon={<Icon name={Icons.Add} fill="#FFFFFF" width={15} height={15} />}
                  iconPosition="left"
                  iconLabelSpacing={10}
                  disabled={docs[0].businessID.status === 'active' ? false : true}
                />
              </Link>
            </CheckPermissions>
          </div>
        </div>

        {isLoading ? (
          <div className="flex flex-col items-center justify-center mt-24">
            <img src={Loader} className="w-32" alt="" />
          </div>
        ) : (
          <div className="overflow-x-auto mt-5 pb-32 panel bg-[#0353A40F] rounded-[20px]">
            <table className="w-full mt-3 text-sm font-medium text-left text-black font-inter">
              <thead className="text-sm text-left border-b border-black/10 font-inter text-black/60">
                <tr>
                  <th scope="col" className="px-6 pt-6 pb-3 min-w-[150px]">
                    Invoice No.
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Vendor
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center ">
                    Amount
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Date
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center  min-w-[150px]">
                    Due Date
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Stages
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Status
                  </th>
                  {(filteredData || invoicesData?.docs || [])?.some(
                    (item) => item.status === 'draft'
                  ) && <th className="px-6 pt-6 pb-3 text-center"> Action </th>}
                </tr>
              </thead>
              <tbody>
                {(filteredData || invoicesData?.docs || [])?.map((item) => (
                  <tr key={item._id} className="bg-white/60">
                    <th
                      scope="row"
                      className="p-5 px-6 font-medium text-gray-900 cursor-pointer hover:underline whitespace-nowrap dark:text-white"
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                    >
                      {item.number}
                    </th>
                    <td
                      className="px-6 py-6  cursor-pointer hover:underline text-center min-w-[150px]"
                      onClick={() => {
                        if (docs[0]?.businessID?._id === item?.businessID?._id) {
                          navigate(`/vendors/${item?.sellerID?._id}`);
                        } else {
                          navigate(`/vendors/${item?.businessID?._id}`);
                        }
                      }}
                    >
                      {filterValue === 'all' &&
                      docs[0]?.businessID?._id !== item?.businessID?._id ? (
                        <p>{shortenName(item.businessID.name)}</p>
                      ) : filterValue === 'buyer' ? (
                        <p>{shortenName(item.businessID.name)}</p>
                      ) : filterValue !== 'buyer' ? (
                        <p>{shortenName(item?.sellerID?.name)}</p>
                      ) : (
                        <p>{shortenName(item?.sellerName)} </p>
                      )}
                    </td>
                    <td className="px-6 py-6 cursor-pointer hover:underline text-center  min-w-[200px]">
                      {formatCurrency(item.currency, item.amount)}
                    </td>
                    <td
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                      className="px-6 py-6 cursor-pointer hover:underline text-center min-w-[150px] "
                    >
                      {format(new Date(item.date), 'dd, MMM yyyy')}
                    </td>
                    <td
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                      className="px-6 py-6 cursor-pointer hover:underline text-center max-[900px]:min-w-[200px] min-w-[150px]"
                    >
                      {getDateStatus(item.requiredEnd)}
                    </td>
                    <td
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                      className="px-6 py-6 cursor-pointer  hover:underline text-center min-w-[230px]"
                    >
                      {getStatusLabel(item.status, filterValue)}
                    </td>
                    <td
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                      className="px-6 py-6 cursor-pointer hover:underline"
                    >
                      <Status
                        status={
                          item.status === InvoiceStatus?.PAID
                            ? InvoiceStatus?.ACTIVE
                            : item.status === InvoiceStatus?.DECLINED_BY_SELLER
                            ? InvoiceStatus?.DECLINED_BY_SELLER
                            : InvoiceStatus.NOT_REGISTERED
                        }
                        title={
                          item.paid || item.status === InvoiceStatus?.PAID
                            ? 'Paid'
                            : 'Pending'
                        }
                      />
                    </td>
                    {
                      <td className="relative w-full">
                        {
                          <button
                            className="p-2 relative z-[1] flex justify-center mx-auto"
                            onClick={() => {
                              handleClick(item);
                            }}
                          >
                            <SlOptionsVertical />
                          </button>
                        }
                        {activeIndex === item._id && (
                          <Action
                            invoice={invoiceId}
                            email={docs[0].businessID.email}
                            name={docs[0].firstName + ' ' + docs[0].lastName}
                            businessId={docs[0].businessID._id}
                            setInvoiceData={setInvoicesData}
                            visibility={item.status}
                            setActiveIndex={setActiveIndex}
                            setRunAfterPayment={setRunAfterPayment}
                          />
                        )}
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-between items-end w-[95%] mx-auto mt-[20px]">
              {(invoicesData?.docs || filteredData || [])?.length > 0 && (
                <PaginationCount
                  dataLimit={invoicesData?.limit}
                  currentPage={invoicesData?.page}
                  totalCount={invoicesData?.totalDocs}
                />
              )}
              <Pagination
                currentPage={currentPage}
                totalCount={invoicesData?.totalPages}
                pageSize={10}
                onPageChange={(page: any) => {
                  setPaginateNumber(page);
                  setCurrentPage(page);
                }}
              />
            </div>
            {!(filteredData || invoicesData?.docs || [])?.length && (
              <EmptyRecord
                title="No invoice to show yet"
                content="You’ve got a  blank state. Create invoice to get started"
                icon={Icons.Invoices}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
