import Button from '../Form/Button';
import Upload from '../Form/Upload';
import { InfoModal } from './InfoModal';

type Props = {
  title?: string;
  content: string;
  actionText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isSubmitting?: boolean;
  cancelClassName?: string;
  actionClassName?: string;
  widthClass?: number;
  formik?: any;
};

export default function ProofModal({
  content,
  title,
  actionText,
  cancelText,
  onConfirm,
  onCancel,
  isSubmitting,
  cancelClassName,
  actionClassName,
  widthClass = 45,
  formik,
}: Props) {
  return (
    <InfoModal
      width={`w-[${widthClass}%] max-[1100px]:w-[70%] max-[780px]:w-[90%] `}
      className="h-fit "
      onClose={onCancel}
    >
      <div className="rounded-[20px] bg-white py-8 min-[600px]:px-8 max-[600px]:px-2 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col items-center justify-between">
          <h2 className="scroll-m-20 min-[600px]:text-3xl max-[600px]:text-[24px] text-center font-semibold tracking-tight first:mt-0">
            {title}{' '}
          </h2>
          <div className="max-w-xs max-[600px]:text-[24px] text-center">
            <p className="text-xl font-medium font-inter">{content}</p>
            <Upload
              label="Upload Receipts"
              getFile={(file) => formik.setFieldValue('receipt', file)}
              error={formik.errors.receipt ?? ''}
              requiredIndicator
            />
          </div>
          <div className="flex max-[480px]:flex-wrap items-center justify-center gap-3 my-3 mt-10">
            <Button
              variant="custom"
              size="custom"
              className={cancelClassName}
              label={cancelText || 'No'}
              onClick={onCancel}
              disabled={isSubmitting}
              loading={isSubmitting}
            ></Button>
            {
              <Button
                variant="custom"
                size="custom"
                className={actionClassName}
                label={actionText || 'Yes'}
                onClick={onConfirm}
                disabled={isSubmitting}
                loading={isSubmitting}
              ></Button>
            }
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
