/* eslint-disable no-unsafe-optional-chaining */
import { useMemo, useState, useRef } from 'react';
import Breadcrumb from '../../../Components/Breadcrumb';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { selectBusiness } from '../../../selectors/business-selector';
import { useGetAudits } from '../../../hooks/queries-and-mutations/audit';
import Loader from '../../../Assests/loader.gif';
import { format } from 'date-fns';
import EmptyRecord from '../../../Components/EmptyRecord';
import { Icon, Icons } from '../../../Components/Icon';
import Chart from './Chart';
import { useGetDashboardStats } from '../../../hooks/queries-and-mutations/dashboard';
import { Invoice } from '../../../types/dashboard.type';
import { formatCurrency, getMonthName } from '../../../utils';
import useOnClickOutside from '../../../CustomHooks/useClickOutside';
import StaggerTable from '../../../Components/Animations/StaggeredTable';
import { staggerContainer } from '../../../Components/Animations/Variant';
import { AnimatePresence, motion } from 'framer-motion';
import FadeIn from '../../../Components/Animations/FadeIn';

export default function Dashboard() {
  const { docs } = useAppSelector(selectBusiness);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [activeInfo, setActiveInfo] = useState('receivables');
  const [selectedCurrency, setSelectedCurrency] = useState('NGN');

  const ref = useRef<any>();

  useOnClickOutside(ref, () => setShowCurrencyDropdown(false));

  const handleToggleInfo = (name: string) => {
    setActiveInfo(name);
  };

  const { data, isLoading } = useGetAudits({
    businessId: docs[0].businessID._id,
  });

  const { data: stats } = useGetDashboardStats({
    businessId: docs[0].businessID._id,
  });

  const metric = useMemo(() => {
    const invoice: Invoice | undefined = stats?.invoice;
    return invoice && invoice?.[activeInfo as keyof Invoice];
  }, [activeInfo, stats?.invoice]);

  const chartData = useMemo(() => {
    const formattedData: { month: string; amount: number }[] = [];

    // Initialize months with zero amount
    const monthsData: { [key: string]: number } = {
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
    };

    if (selectedCurrency === 'NGN') {
      // Update the months with actual data
      if (metric?.paymentAnalytics[0]?.naira) {
        for (const entry of metric?.paymentAnalytics[0]?.naira) {
          const month = entry._id.month.toString();
          monthsData[month] = entry.amount;
        }
        // Create the final formatted list
        for (let i = 1; i <= 12; i++) {
          formattedData.push({
            month: getMonthName(i),
            amount: monthsData[i.toString()],
          });
        }
      }
    } else if (selectedCurrency === 'USD') {
      if (metric?.paymentAnalytics[0]?.dollar) {
        for (const entry of metric?.paymentAnalytics[0]?.naira) {
          const month = entry._id.month.toString();
          monthsData[month] = entry.amount;
        }
        // Create the final formatted list
        for (let i = 1; i <= 12; i++) {
          formattedData.push({
            month: getMonthName(i),
            amount: monthsData[i.toString()],
          });
        }
      }
    }
    return formattedData;
  }, [metric?.paymentAnalytics, selectedCurrency]);

  const totalTransaction = useMemo(() => {
    if (selectedCurrency === 'NGN') {
      return activeInfo === 'receivables'
        ? stats?.invoice.receivables.totalValue.NGN.totalAmount
        : stats?.invoice.payables.totalValue.NGN.totalAmount;
    }
    if (selectedCurrency === 'USD') {
      return activeInfo === 'receivables'
        ? stats?.invoice.receivables.totalValue.USD.totalAmount
        : stats?.invoice.payables.totalValue.USD.totalAmount;
    }
  }, [activeInfo, selectedCurrency, stats]);

  return (
    <div>
      <div className="flex items-center w-full justify-between">
        <Breadcrumb text="Dashboard" />
        <div className="relative z-10 inline-flex align-middle">
          <div className="w-[7rem] btn rounded-lg bg-[#0353A4] text-white z-10 h-12">
            Currency
          </div>

          <div className="z-50 relative -ml-3 dropdown" ref={ref}>
            <button
              type="button"
              className="z-30 h-12 bg-white btn rounded-e-lg"
              onClick={() => setShowCurrencyDropdown((prevState) => !prevState)}
            >
              {selectedCurrency}{' '}
              <Icon name={showCurrencyDropdown ? Icons.CaretUp : Icons.CaretDown} />
            </button>
            <AnimatePresence>
              {showCurrencyDropdown && (
                <FadeIn>
                  <ul className="px-3 absolute left-[-30px] z-40 whitespace-nowrap">
                    <li
                      onClick={() => {
                        setSelectedCurrency('NGN');
                        setShowCurrencyDropdown(false);
                      }}
                    >
                      <button type="button">NGN</button>
                    </li>
                    <li
                      onClick={() => {
                        setSelectedCurrency('USD');
                        setShowCurrencyDropdown(false);
                      }}
                    >
                      <button type="button">USD</button>
                    </li>
                  </ul>
                </FadeIn>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>

      <div className="panel bg-white p-9 mt-7 rounded-[20px]">
        <div className="flex max-[540px]:flex-col gap-4 min-[540px]:items-center max-[540px]:items-start justify-between">
          <p className="text-lg font-medium">Transaction Metrics</p>
          <div className="relative inline-flex align-middle">
            <button
              type="button"
              className={`w-[7rem] btn rounded-lg ${
                activeInfo === 'receivables'
                  ? 'bg-[#0353A4] text-white z-[1]'
                  : 'bg-[#B9D6F26E] text-black'
              } h-12 `}
              onClick={() => handleToggleInfo('receivables')}
            >
              Receivable
            </button>

            <button
              type="button"
              className={`w-[7rem] btn rounded-nonse ${
                activeInfo === 'payables'
                  ? 'bg-[#0353A4] text-white z-[1]'
                  : 'bg-[#B9D6F26E] text-black'
              } rounded-e-lg h-12 -ml-3`}
              onClick={() => handleToggleInfo('payables')}
            >
              Payable
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 my-6 text-black sm:grid-cols-2 xl:grid-cols-3">
          {/* Transaction */}
          <div className="panel bg-[#B9D6F226] rounded-lg p-4">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-black/30">Transactions</div>
            </div>
            <div className="flex items-center mt-5">
              <div className="text-2xl font-medium">
                {formatCurrency(selectedCurrency, totalTransaction ?? 0)}
                {/* {activeInfo === 'receivables'
                  ? stats?.transactionReceivables.count
                  : stats?.transactionPayables.count} */}
              </div>
            </div>
            <div className="flex items-center mt-5 font-medium text-black/70">
              Transaction value -{' '}
              {activeInfo === 'receivables'
                ? stats?.transactionReceivables.count
                : stats?.transactionPayables.count}{' '}
            </div>
          </div>
          {/* Invoice */}
          <div className="panel bg-[#B9D6F226]  rounded-lg p-4">
            <div className="flex flex-col  justify-between">
              <div className="text-sm w-full font-medium text-black/30">
                Total Invoice Number
              </div>
              <div className="flex items-end gap-2 justify-between">
                <div className="">
                  <div className="flex items-center mt-5">
                    <div className="text-2xl font-medium">
                      {metric?.count?.total || 0}
                    </div>
                  </div>
                  <div className="flex items-center mt-5 font-medium text-black/70">
                    <div className="text-sm font-medium text-black/30">
                      {metric?.count?.outstanding || 0} outstanding
                    </div>
                  </div>
                </div>
                <div className="flex mt-8 border-l h-14" />
                <div className="mt-5 pl-9 mr-9 ">
                  <div className="flex flex-col mt-5">
                    <div className="text-sm font-medium text-black/70">
                      {metric?.count?.paid || 0} paid{' '}
                    </div>
                  </div>
                  <div className="flex items-center mt-5 font-medium text-black/70">
                    {metric?.count?.overdue || 0} overdue
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Next receivable Amount */}
          <div className="panel bg-[#B9D6F226] rounded-lg p-4">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-black/30">
                Next {activeInfo === 'receivables' ? 'Receivable' : 'Payable'} Amount
              </div>
            </div>
            <div className="flex items-center mt-5">
              <div className="text-2xl font-medium">
                {activeInfo === 'receivables'
                  ? stats?.nextReceivables?.nextReceivablesDollar?.currency &&
                    selectedCurrency === 'USD'
                    ? formatCurrency(
                        stats?.nextReceivables?.nextReceivablesDollar?.currency,
                        stats?.nextReceivables?.nextReceivablesDollar?.amount ?? 0
                      )
                    : stats?.nextReceivables?.nextReceivablesNaira?.currency &&
                      selectedCurrency === 'NGN'
                    ? formatCurrency(
                        stats?.nextReceivables?.nextReceivablesNaira?.currency,
                        stats?.nextReceivables?.nextReceivablesNaira?.amount ?? 0
                      )
                    : formatCurrency(selectedCurrency, 0)
                  : stats?.nextPayables.nextPayablesDollar?.currency &&
                    selectedCurrency === 'USD'
                  ? formatCurrency(
                      stats?.nextPayables.nextPayablesDollar?.currency,
                      stats?.nextPayables?.nextPayablesDollar?.amount ?? 0
                    )
                  : stats?.nextPayables?.nextPayablesNaira?.currency &&
                    selectedCurrency === 'NGN'
                  ? formatCurrency(
                      stats?.nextPayables?.nextPayablesNaira?.currency,
                      stats?.nextPayables?.nextPayablesNaira?.amount ?? 0
                    )
                  : formatCurrency(selectedCurrency, 0)}
              </div>
            </div>
            <div className="flex items-center mt-5 font-medium text-black/70">
              {activeInfo === 'receivables'
                ? stats?.nextReceivables?.nextReceivablesNaira?.date &&
                  format(
                    new Date(stats?.nextReceivables?.nextReceivablesNaira?.date),
                    'dd MMM yyyy'
                  )
                : stats?.nextPayables?.nextPayablesNaira?.date &&
                  format(
                    new Date(stats?.nextPayables.nextPayablesNaira?.date),
                    'dd MMM yyyy'
                  )}{' '}
              Due date
            </div>
          </div>
        </div>
      </div>

      <div className="panel bg-white p-9 mt-7 rounded-[20px]">
        <div className="flex items-center justify-between">
          <p className="text-lg font-medium">Payment Analytics</p>
        </div>
        <div className="mt-5">
          <Chart chartData={chartData} />
        </div>
      </div>

      {/* Other Metrics */}
      <div className="panel bg-white p-9 mt-7 rounded-[20px]">
        <div className="flex items-center justify-between">
          <p className="text-lg font-medium">Other Metrics</p>
        </div>
        <div className="grid grid-cols-1 gap-6 my-6 text-black sm:grid-cols-2 xl:grid-cols-4">
          {/* User count */}
          <div className="panel bg-[#B9D6F226] rounded-lg p-4 h-32">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-black/30">User count</div>
            </div>
            <div className="flex items-center mt-5">
              <div className="text-2xl font-medium">{stats?.userCount ?? 0}</div>
            </div>
          </div>
          {/* Department count */}
          <div className="panel bg-[#B9D6F226] rounded-lg p-4 h-32">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-black/30">Department count</div>
            </div>
            <div className="flex items-center mt-5">
              <div className="text-2xl font-medium">{stats?.departmentCount ?? 0}</div>
            </div>
          </div>
          {/* Buyers count */}
          <div className="panel bg-[#B9D6F226] rounded-lg p-4 h-32">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-black/30">Buyers count</div>
            </div>
            <div className="flex items-center mt-5">
              <div className="text-2xl font-medium">{stats?.buyerCount ?? 0}</div>
            </div>
          </div>
          {/* Suppliers count */}
          <div className="panel bg-[#B9D6F226] rounded-lg p-4 h-32">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-black/30">Suppliers count</div>
            </div>
            <div className="flex items-center mt-5">
              <div className="text-2xl font-medium">{stats?.supplierCount ?? 0}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Activity Logs */}
      <div>
        {isLoading ? (
          <div className="flex flex-col items-center justify-center mt-24">
            <img src={Loader} className="w-32" alt="" />
          </div>
        ) : (
          <div className="relative overflow-x-auto mt-5 panel bg-[#0353A40F] rounded-[20px]">
            {(data?.docs || [])?.length > 0 ? (
              <>
                <div className="flex items-center justify-between mr-12 p-7">
                  <p className="text-lg font-medium">Activity Log</p>
                  <Link
                    to="/audit-trail"
                    className="text-[#0353A4] text-base font-medium"
                  >{`View all >`}</Link>
                </div>
                <table className="w-full text-sm font-medium text-left text-black font-inter">
                  <thead className="text-sm text-left border-b border-black/10 font-inter text-black/60">
                    <tr>
                      <th scope="col" className="px-6 pt-6 pb-3">
                        Date & Time
                      </th>
                      <th scope="col" className="px-6 pt-6 pb-3">
                        Collaborator
                      </th>
                      <th scope="col" className="px-6 pt-6 pb-3">
                        Role
                      </th>
                      <th scope="col" className="px-6 pt-6 pb-3">
                        Action
                      </th>
                      {/* <th scope="col" className="px-6 pt-6 pb-3">
                    Action
                  </th> */}
                    </tr>
                  </thead>
                  <motion.tbody
                    variants={staggerContainer}
                    initial="hidden"
                    animate="show"
                  >
                    {(data?.docs ?? []).splice(0, 4)?.map((item, index) => (
                      <StaggerTable
                        className={index % 2 ? 'bg-[#EBEBEB17]' : 'bg-white/60'}
                        key={`log-${index}`}
                      >
                        <th
                          scope="row"
                          className="p-5 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {`${format(new Date(item?.createdAt), 'dd MMM yyyy')} ${format(
                            new Date(item?.createdAt),
                            'HH:mm:ss'
                          )}`}
                        </th>
                        <td className="px-6 py-4">{item?.collaborator ?? ''}</td>
                        <td className="px-6 py-4">{item.role ?? ''}</td>
                        <td className="px-6 py-4 min-w-[180px]">{item?.action ?? ''}</td>
                        {/* <td className="px-6 py-4 text-center">
                      <Link to="659510959849b12684650ac8">View Product</Link>
                    </td> */}
                      </StaggerTable>
                    ))}
                  </motion.tbody>
                </table>
              </>
            ) : (
              <EmptyRecord
                title="No activity to show yet"
                content="You’ve got a  blank state. We’ll let you know when an activity arrives"
                icon={Icons.Audit}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
