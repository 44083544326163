import React from 'react';
import Button from './Form/Button';
import { Icon, Icons } from './Icon';
import { TransactionDoc } from '../types/transaction.type';
import pdfMake from 'pdfmake/build/pdfmake';
import { formatCurrency } from '../utils';
import logo from '../Assests/logo-dark.png';
import { format } from 'date-fns';

type IProps = {
  transaction: TransactionDoc;
};

const TransactionPdf: React.FC<IProps> = ({ transaction }) => {
  const downloadPDF = () => {
    const storedCurrency = formatCurrency(
      transaction?.currency === 'Dollars' ? 'usd' : transaction?.currency,
      transaction?.amount
    );

    const bgColor = '#ffffff';
    const keyColor = '#7F7F7F';

    const layoutFunction = (
      i?: number | string | null,
      lineColor?: string,
      paddingTop?: number,
      paddingBottom?: number
    ) => {
      return {
        hLineWidth: function () {
          return i ? (i === 1 || i === 3 ? 1 : 0) : 0;
        },
        hLineColor: function () {
          return lineColor;
        },
        vLineWidth: function () {
          return 0;
        },
        paddingLeft: function () {
          return 0;
        },
        paddingRight: function () {
          return 0;
        },
        paddingTop: function () {
          return paddingTop;
        },
        paddingBottom: function () {
          return paddingBottom;
        },
      };
    };

    pdfMake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        // eslint-disable-next-line prettier/prettier
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    const docDefinition: any = {
      pageSize: {
        width: 500, // Half of A4 width (A4 is 595 points wide)
        height: 'auto', // A4 height is 842
      },
      styles: {
        textSection: {
          fontSize: 12,
          color: '#333',
        },
        headerAmount: {
          fontSize: 16,
          bold: true,
          lineHeight: 1.7,
        },
        header: {
          fontSize: 10,
          lineHeight: 1.7,
        },
      },
      background: [
        {
          // Background color
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 595.28,
              h: 841.89,
              color: bgColor,
            },
          ],
        },
      ],
      defaultStyle: {
        font: 'Roboto',
        lineHeight: 1.7,
        // font: 'poppins',
      },

      content: [
        {
          style: 'entry',
          alignment: 'justify',
          columns: [
            {
              width: '*',
              stack: [
                {
                  image: logo,
                  width: 100,
                  alignment: 'left',
                },
              ],
            },
            {
              style: 'header',
              text: 'Transaction Details',
              alignment: 'right',
            },
          ],
        },
        {
          text: 'Transaction Amount',
          style: 'header',
          alignment: 'center',
          margin: [0, 20, 0, 0],
        },
        {
          text: storedCurrency,
          style: 'headerAmount',
          alignment: 'center',
        },
        {
          style: 'textSection',
          margin: [0, 20, 0, 0], // Margin for spacing above the section
          table: {
            widths: ['*', '*'], // Two equal columns
            body: [
              [
                {
                  text: 'Vendor',
                  color: keyColor,
                },
                {
                  text: transaction?.vendorName,
                  alignment: 'right', // Align to the right
                },
              ],
            ],
          },
          layout: layoutFunction(1, '#E5E7EB', 10, 5),
        },
        {
          style: 'textSection',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'Invoice number',
                  color: keyColor,
                  border: [false, false, false, true], // Bottom border
                },
                {
                  text: transaction?.invoice?.number,
                  alignment: 'right', // Align to the right
                },
              ],
            ],
          },
          layout: layoutFunction(1, '#E5E7EB', 10, 5),
        },

        // Section 2
        {
          style: 'textSection',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'Date and time',
                  color: keyColor,
                },
                {
                  text: transaction?.createdAt
                    ? `${format(
                        new Date(transaction?.createdAt),
                        'dd, MMM yyyy'
                      )} | ${format(new Date(transaction?.createdAt), 'HH:mm:ss')}`
                    : 'N/A',
                  alignment: 'right', // Align to the right
                },
              ],
            ],
          },
          layout: layoutFunction(1, '#E5E7EB', 10, 5),
        },
        // Section 3
        {
          style: 'textSection',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'Description',
                  color: keyColor,
                },

                {
                  text: `${
                    transaction?.paidAt
                      ? format(new Date(transaction?.paidAt), 'dd, MMM yyyy')
                      : ''
                  }`,
                  alignment: 'right',
                  border: [false, false, false, true], // Bottom border
                },
              ],
            ],
          },
          layout: layoutFunction(1, '#E5E7EB', 10, 5),
        },
        // Section 4
        {
          style: 'textSection',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'Transaction reference',
                  color: keyColor,
                },
                {
                  text: transaction?.id,
                  alignment: 'right', // Align to the right
                },
              ],
            ],
          },
          layout: layoutFunction(1, '#E5E7EB', 10, 5),
        },
        // Section 5
        {
          style: 'textSection',
          margin: [0, 0, 0, 50],
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'Payment type',
                  color: keyColor,
                },

                {
                  text: transaction?.paymentType,
                  alignment: 'right', // Align to the right
                },
              ],
            ],
          },
          layout: layoutFunction(1, '#E5E7EB', 10, 5),
        },
      ],
    };
    pdfMake.createPdf(docDefinition).download('Paytton_transaction_receipt.pdf');
  };

  return (
    <Button
      type="button"
      variant="primary"
      size="lg"
      label="DownloadReceipt"
      className={`mt-10`}
      icon={<Icon name={Icons.Download} fill="#fff" />}
      iconPosition="left"
      iconLabelSpacing={10}
      onClick={downloadPDF}
    />
  );
};

export default TransactionPdf;
