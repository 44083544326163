import { useMemo, useState, useRef, useEffect } from 'react';
import Invoice from './Invoice';
import Breadcrumb from '../../../../Components/Breadcrumb';
import ConfirmModal from '../../../../Components/Modal/ConfirmModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import DeclineModal from '../../../../Components/Modal/Invoice/DeclineModal';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import SendReminderModal from '../../../../Components/Modal/Invoice/SendReminderModal';
import ProofOfPaymentModal from '../../../../Components/Modal/Invoice/ProofOfPaymentModal';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { useGetInvoice } from '../../../../hooks/queries-and-mutations/invoice';
import Loader from '../../../../Assests/loader.gif';
import ReceiveableApprovalFlow from './ReceiveableApprovalFlow';
import PdfDownload from '../../../../Components/PdfDownload';
import PaymentHistory from './PaymentHistory';
import { useGetTransactions } from '../../../../hooks/queries-and-mutations/transaction';
import { TransactionDoc } from '../../../../types/transaction.type';
import { InvoiceStatus } from '../../../../types/invoice.type';
import Button from '../../../../Components/Form/Button';
import ConfirmPaymentModal from '../../../../Components/Modal/ConfirmPaymentModal';
import ProofModal from '../../../../Components/Modal/ProofModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { markInvoice } from '../../../../service/invoice';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const EnhancedDeclineModal = withCreatePortal(DeclineModal);
const EnhancedSuccessModal = withCreatePortal(SuccessModal);
const EnhancedSendReminderModal = withCreatePortal(SendReminderModal);
const EnhancedProofOfPaymentModal = withCreatePortal(ProofOfPaymentModal);
const EnhancedConfirmModal = withCreatePortal(ConfirmPaymentModal);
const EnhancedReceiptModal = withCreatePortal(ProofModal);

export default function InvoiceView() {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showSuccessModalForReminder, setShowSuccessModalForReminder] = useState(false);
  const [showPaymentProofModal, setShowPaymentProofModal] = useState(false);
  const [history, setHistory] = useState<TransactionDoc[] | null>(null);
  const [confirmationModalActive, setConfirmationModalActive] = useState<boolean>(false);
  const [markInvoiceModal, setMarkInvoiceModal] = useState<boolean>(false);
  const [markInvoiceForSeller, setMarkInvoiceFromSeller] = useState<boolean>(false);
  const [proceedWithPayment, setProceedWithPayment] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { invoiceId } = useParams();
  const { docs } = useAppSelector(selectBusiness);
  // eslint-disable-next-line prettier/prettier
  const { data: invoice, isLoading, refetch } = useGetInvoice({
    businessId: docs[0].businessID._id,
    invoiceId: invoiceId!,
  });

  const pdfRef = useRef(null);

  const isReceiveableInvoice = useMemo(() => {
    if (docs[0].businessID._id === invoice?.invoice.businessID?._id) {
      return invoice?.invoice.businessID?._id === docs[0].businessID._id;
    } else {
      invoice?.invoice.sellerID?._id === docs[0].businessID._id;
      return invoice?.invoice.sellerID?._id === docs[0].businessID._id;
    }
  }, [docs, invoice?.invoice.sellerID._id]);

  const { data: Transact, isLoading: Loading } = useGetTransactions({
    businessId: docs[0].businessID._id,
  });

  useEffect(() => {
    const response = Transact?.docs?.filter(
      (res) => res?.invoice?.number === invoice?.invoice?.number
    );
    if (response) {
      setHistory(response);
    }
  }, [Transact?.docs, invoice?.invoice?.number]);

  const shouldShowButton = () => {
    const isApproved = invoice?.invoice?.status === InvoiceStatus.APPROVED;
    const isSellerInternalApproved =
      invoice?.invoice?.status === InvoiceStatus.SELLER_INTERNAL_APPROVED;
    const isAwaitingCustomerApproval =
      invoice?.invoice?.status === InvoiceStatus.AWAITING_APPROVAL_BUYER;
    const isPaid = invoice?.invoice?.status === InvoiceStatus.PAID;

    return isApproved || isSellerInternalApproved || isAwaitingCustomerApproval || isPaid;
  };

  const formik = useFormik({
    initialValues: {
      receipt: null,
    },
    validationSchema: Yup.object({
      receipt: Yup.mixed().required().nullable(),
    }),
    onSubmit: async (values) => {
      if (values.receipt && docs[0]?.businessID?._id && invoice?.invoice?._id) {
        const formData = new FormData();
        formData.append('proof', values.receipt);
        await markInvoice(docs[0]?.businessID?._id, invoice?.invoice?._id, formData)
          .then(() => {
            toast.success('Invoice successfully marked as paid');
          })
          .catch((error) => {
            const err = error as AxiosError<Error>;
            toast.error(err?.message ?? 'Sorry!, An error as occurred');
          });
      }
    },
  });

  const handleMarkAsPaidFromSeller = async () => {
    //Handle mark as paid from seller
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center mt-24">
          <img src={Loader} className="w-32" alt="" />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between py-5">
            <Breadcrumb text="Invoices" subText={invoice?.invoice?.number} />
            <div className="flex w-full items-center justify-end gap-3 mr-3">
              {shouldShowButton() ? (
                <div className="flex items-center justify-end gap-3">
                  {docs[0]?.businessID?._id === invoice?.invoice?.sellerID?._id ? (
                    <Button
                      type="button"
                      variant="transparent"
                      size="custom"
                      label="Mark as paid"
                      className={`px-5 py-2.5 border border-[#0353A4] text-sm bg-transparent text-[#0353A4]}`}
                      iconPosition="right"
                      iconLabelSpacing={3}
                      onClick={() => {
                        setMarkInvoiceModal(true);
                      }}
                    />
                  ) : (
                    <Button
                      type="button"
                      variant="transparent"
                      size="custom"
                      label="Mark as paid"
                      className={`px-5 py-2.5 border border-[#0353A4] text-sm bg-transparent text-[#0353A4]}`}
                      iconPosition="right"
                      iconLabelSpacing={3}
                      onClick={() => {
                        setMarkInvoiceFromSeller(true);
                      }}
                    />
                  )}
                  <Button
                    type="button"
                    variant="primary"
                    size="custom"
                    label="Make payment"
                    className={`px-5 py-2.5 text-sm}`}
                    iconPosition="right"
                    iconLabelSpacing={3}
                    onClick={() => {
                      setConfirmationModalActive(true);
                    }}
                    disabled={invoice?.invoice?.status === InvoiceStatus?.PAID}
                  />
                </div>
              ) : (
                ''
              )}
              <PdfDownload invoice={invoice?.invoice} />
            </div>
          </div>

          <div className="panel p-5 rounded-[20px] bg-white flex flex-col" ref={pdfRef}>
            <p className="text-base font-bold text-black font-inter">Status</p>

            {/* {isPayableInvoice && invoice?.invoice.buyerApprovalNextLevel && (
              <PayableApprovalFlow
                buyerApprovalNextLevel={invoice?.invoice.buyerApprovalNextLevel}
                invoice={invoice?.invoice}
                getInvoice={refetch}
              />
            )} */}
            {isReceiveableInvoice && invoice?.invoice.sellerApprovalNextLevel && (
              <ReceiveableApprovalFlow
                sellerApprovalNextLevel={invoice?.invoice.sellerApprovalNextLevel}
                buyerApprovalNextLevel={invoice?.invoice?.buyerApprovalNextLevel}
                invoice={invoice?.invoice}
                getInvoice={refetch}
              />
            )}
          </div>

          {/* Integrate the Invoice component here */}
          <Invoice invoice={invoice?.invoice} />

          <div>
            <div className="bg-white px-6 py-[24px] rounded-tl-[10px] rounded-tr-[10px] mt-8">
              <h2 className="font-bold text-[20px]">Payment History </h2>
            </div>
            <PaymentHistory history={history} loading={Loading} />
          </div>
        </>
      )}

      {showDeclineModal && (
        <EnhancedDeclineModal onCancel={() => setShowDeclineModal(false)} />
      )}

      {showReminderModal && (
        <EnhancedSendReminderModal
          onCancel={() => setShowReminderModal(false)}
          onSuccess={() => {
            setShowReminderModal(false);
            setShowSuccessModalForReminder(true);
          }}
        />
      )}

      {showPaymentProofModal && (
        <EnhancedProofOfPaymentModal onCancel={() => setShowPaymentProofModal(false)} />
      )}

      {showSuccessModalForReminder && (
        <EnhancedSuccessModal
          title="Reminder Sent"
          description="A reminder has been sent to Favorite Promise"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          content={'Are you sure you want to approve this invoice ?'}
          onConfirm={() => {
            setShowSuccessModal(true);
            setShowConfirmModal(false);
          }}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Approve"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
        />
      )}

      {showSuccessModal && (
        <EnhancedSuccessModal
          title="Invoice Approved"
          description="You have approved this invoice "
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}

      {confirmationModalActive && (
        <EnhancedConfirmModal
          title="Make a payment"
          content={`Payment would be made to `}
          invoiceName={invoice?.invoice?.sellerName}
          actionText="Proceed with Payment"
          onCancel={() => {
            setConfirmationModalActive(false);
            setProceedWithPayment(false);
            setIsSubmitting(false);
          }}
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full min-w-fit px-3 py-3 text-base bg-[#061A40] rounded font-normal text-center text-white"
          onPaymentClose={() => {
            toast.error('Payment closed');
          }}
          businessId={docs[0]?.businessID?._id}
          invoiceId={invoice?.invoice?._id}
          email={docs[0]?.businessID?.email}
          amount={invoice?.invoice?.amount}
          currency={invoice?.invoice?.currency}
          continuePayment={proceedWithPayment}
          onConfirm={() => {
            setProceedWithPayment(true);
          }}
          onBack={() => {
            setProceedWithPayment(false);
            setIsSubmitting(false);
          }}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          setConfirmationModalActive={setConfirmationModalActive}
          setProceedWithPayment={setProceedWithPayment}
        />
      )}
      {markInvoiceModal && (
        <EnhancedReceiptModal
          title="Submit a Receipt"
          invoiceName={invoice?.invoice?.sellerName}
          actionText="Submit Receipt"
          onCancel={() => {
            setMarkInvoiceModal(false);
          }}
          onConfirm={() => {
            formik.handleSubmit();
          }}
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full px-3 py-3 text-base bg-[#061A40] rounded font-normal text-white"
          onPaymentClose={() => {
            toast.error('Payment close');
          }}
          businessId={docs[0]?.businessID?._id}
          invoiceId={invoice?.invoice?._id}
          formik={formik}
        />
      )}
      {markInvoiceForSeller && (
        <EnhancedConfirmModal
          title="Mark as paid"
          content={`Please confirm that you want to mark invoice as paid `}
          actionText="Mark as paid"
          onCancel={() => {
            setMarkInvoiceFromSeller(false);
            setIsSubmitting(false);
          }}
          onConfirm={() => {
            handleMarkAsPaidFromSeller();
          }}
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full min-w-fit px-3 py-3 text-base bg-[#061A40] rounded font-normal text-center text-white"
          onPaymentClose={() => {
            toast.error('Payment closed');
          }}
          businessId={docs[0]?.businessID?._id}
          invoiceId={invoice?.invoice?._id}
        />
      )}
    </div>
  );
}
