import React, { useCallback } from 'react';

type Props = {
  steps: { title: string; key: string; id: number; hasNext: boolean }[];
  path: string;
};

export default function Step({ steps, path }: Props) {
  const renderStepInfo = useCallback(() => {
    if (path.includes('contact')) {
      return (
        <h2 className="-mx-4 mb-10 flex flex-col py-3 font-extrabold">
          <span className="text-lg font-medium font-inter mb-2">Step 1</span>
          <span className="text-lg font-semibold font-inter">Contact Information</span>
          <span className="text-sm font-normal font-inter text-[#FFFFFF8C]">
            Input company contact Information
          </span>
        </h2>
      );
    } else if (path.includes('kyb')) {
      return (
        <h2 className="-mx-4 mb-10 flex flex-col py-3 font-extrabold">
          <span className="text-lg font-medium font-inter mb-2">Step 2</span>
          <span className="text-lg font-semibold font-inter">KYB</span>
          <span className="text-sm font-normal font-inter text-[#FFFFFF8C]">
            Input details to verify company
          </span>
        </h2>
      );
    } else {
      return (
        <h2 className="-mx-4 mb-10 flex flex-col py-3 font-extrabold">
          <span className="text-lg font-medium font-inter mb-2">Step 3</span>
          <span className="text-lg font-semibold font-inter">Preview</span>
          <span className="text-sm font-normal font-inter text-[#FFFFFF8C]">
            Verify your detail
          </span>
        </h2>
      );
    }
  }, [path]);

  return (
    <div>
      {renderStepInfo()}
      {steps.map((step) => (
        <div className="pb-5" key={step.id}>
          <div className="pb-3 mb-[4.5rem] ms-6">
            <span className="absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-1 ring-white">
              {step.id}
            </span>
            <h3
              className={`text-sm pt-1 font-normal leading-tight ${
                step.id === 1 ? '' : 'opacity-100'
              }`}
            >
              {step.title}
            </h3>
          </div>
          {step.hasNext && (
            <div className="w-16 h-px absolute origin-top-left -rotate-90 opacity-40 border-[0.5px] border-[#FFFFFF66] border-opacity-40" />
          )}
        </div>
      ))}
    </div>
  );
}
